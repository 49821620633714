<template>
  <DashboardSection>
    <template #content>
      <div class="block-wrapper">
        <OverviewBlock v-for="item in overViewBlockData" :key="item.id">
          <template #heading>
            {{ item.blockHeading }}
            <!-- <span v-if="item.blockHeading == 'Growth'" class="subtext">Next 3 month</span> -->
          </template>
          <template #questionIcon>
            <Tooltip
              :tooltipTitle="item.blockHeading"
              :tooltipText="item.tooltipText"
              :fieldType="item.blockHeading === 'Growth' ? 'growth' : 'dashboard'"
              class="inline-flex ml-auto"
            >
              <img src="@/assets/icons/question-mark.svg" />
            </Tooltip>
          </template>
          <template #status>
            <span class="block__status green-dot" v-if="item.score > 90"></span>
            <span class="block__status mustard-dot" v-if="item.score >= 70 && item.score <= 90"></span>
            <span class="block__status red-dot" v-if="item.score < 70"></span>
          </template>
          <template #score>
            {{ item.blockHeading == 'Growth' ? '' + item.score : item.score + '%' }}
          </template>
          <template #performance>
            <p class="avg"><span class="arrow-up"></span> -</p>
            <!-- <span>30 day rolling avg.</span> -->
          </template>
          <template #blockFields>
            <div class="block__row" v-for="fields in item.fields" :key="fields.id">
              <router-link v-if="fields.link !== null" :to="fields.link">
                <p class="block__fieldName">{{ fields.name }}</p>
              </router-link>
              <p v-else class="block__fieldName">{{ fields.name }}</p>
              <p class="block__value" v-if="item.blockHeading == 'Control'">
                {{ fields.percentage }}%
                <span class="dot green-dot" v-if="fields.percentage == 100"></span>
                <span class="dot mustard-dot" v-if="fields.percentage >= 75 && fields.percentage <= 99"></span>
                <span class="dot red-dot" v-if="fields.percentage < 75"></span>
              </p>
              <p class="block__value" v-else-if="item.blockHeading == 'Performance'">
                <span>{{ fields.name !== 'Profit Recovery' ? '' + fields.percentage : fields.percentage + '%' }}</span>
                <span class="dot green-dot" v-if="fields.percentage >= 95"></span>
                <span class="dot mustard-dot" v-if="fields.percentage >= 80 && fields.percentage <= 94"></span>
                <span class="dot red-dot" v-if="fields.percentage <= 79"></span>
              </p>
              <p class="block__value" v-else>
                <span>{{ fields.name !== 'Team Size' ? '' : '' }}{{ fields.percentage }}</span>
              </p>
            </div>
          </template>
        </OverviewBlock>
        <WideBlock v-for="item in wideBlockData" :key="item.id">
          <template #wideBlockContent>
            <div class="block__row">
              <h3 class="block__heading">{{ item.blockHeading }}</h3>
              <span class="block_button">
                <CustomButton :label="'See all'" color="grey" size="small" />
              </span>
            </div>
            <div v-for="content in item.content" :key="content.id">
              <router-link
                :to="{ name: content.link, params: { id: projectId } }"
                v-if="content.taskName && content.link"
              >
                <div class="block__row">
                  <p class="block__value" v-if="item.blockHeading == 'Insights'">
                    <!-- <span class="dot purple-dot"></span> <strong>{{ content.taskName }}</strong> for
                <strong>{{ content.projectName }}</strong> -->
                    <strong>Your project insights will appear here</strong>
                  </p>
                  <!-- <span class="block_datetime" v-if="item.blockHeading == 'Activity'">16:04, 21/04/21 </span> -->
                  <p v-else>{{ content.taskName }}</p>
                </div>
              </router-link>
            </div>
          </template>
        </WideBlock>
      </div>
    </template>
  </DashboardSection>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import WideBlock from '@/components/atom/WideBlock.vue'
import { useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import CustomButton from '@/components/atom/CustomButton.vue'
import { intlFormat } from '@/utils/currencyFunctions'
import Tooltip from '@/components/atom/Tooltip.vue'
import { projectTypes } from '@shared/*'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    DashboardSection,
    OverviewBlock,
    WideBlock,
    CustomButton,
    Tooltip
  },
  setup() {
    const route = useRoute()
    const projectId = route.params.id
    const routerLink = ref()

    const projectDashboard = ref<projectTypes.ProjectDashboardResponse>({
      ProjectDashboardCalculations: []
    })
    const taskContent = [
      {
        id: 41,
        taskName: '',
        link: ''
      }
    ]
    onMounted(async () => {
      projectDashboard.value = await authApi.getProjectDashboardDetail(+projectId)
      if (
        projectDashboard.value &&
        projectDashboard.value.ProjectDashboardCalculations.length > 0 &&
        projectDashboard.value.ProjectDashboardCalculations[0].tasks &&
        JSON.parse(projectDashboard.value.ProjectDashboardCalculations[0].tasks).length > 0
      ) {
        JSON.parse(projectDashboard.value.ProjectDashboardCalculations[0].tasks).map(
          (task: { task: string; type: string }, index: number) => {
            if (task.type === 'document') {
              routerLink.value = 'ProjectDocuments'
            } else if (task.type === 'invoice') {
              routerLink.value = 'ProjectInvoicing'
            }
            taskContent.push({ id: 4 + index + 1, taskName: task.task, link: routerLink.value })
          }
        )
      }
    })

    const roundValue = (percentage: number) => Math.round(percentage * 100)
    const roundAmount = (amount: number) => Math.round(amount)
    const overViewBlockData = computed(() => {
      return [
        {
          id: 1,
          blockHeading: 'Control',
          score:
            projectDashboard.value.ProjectDashboardCalculations.length > 0
              ? roundValue(projectDashboard.value.ProjectDashboardCalculations[0].controlScore)
              : '-',
          tooltipText:
            'How much are you in control of the basics? The degree to which all your documents are in place, your invoices are sent out on time and cash is received. 100% = fully in control. Less than 100% means something is missing. Your task bar will tell you what to do.',
          fields: [
            {
              id: 11,
              name: 'Documents',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? roundValue(projectDashboard.value.ProjectDashboardCalculations[0].controlDocuments)
                  : '-',
              subText: '-',
              link: `/projects/${projectId}/documents`
            },
            {
              id: 12,
              name: 'Invoices',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? roundValue(projectDashboard.value.ProjectDashboardCalculations[0].controlInvoices)
                  : '-',
              subText: '-',
              link: `/projects/${projectId}/invoicing`
            },
            {
              id: 13,
              name: 'Cash flow',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? roundValue(projectDashboard.value.ProjectDashboardCalculations[0].controlCashFlow)
                  : '-',
              subText: '-',
              link: `/projects/${projectId}/invoicing`
            }
          ]
        },
        {
          id: 2,
          blockHeading: 'Performance',
          score:
            projectDashboard.value.ProjectDashboardCalculations.length > 0
              ? roundValue(projectDashboard.value.ProjectDashboardCalculations[0].performanceProfitRecovery)
              : '-',
          tooltipText:
            'How well is your project performing in terms of revenue generation, and profitability? Key is profit recovery, your budgeted or expected profit v actual. 100% means all profit is recovered. Less that 100% means you are putting more time into your project that you are budgeting for.',
          fields: [
            {
              id: 21,
              name: 'Total Revenue',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? intlFormat(projectDashboard.value.ProjectDashboardCalculations[0].performanceTotalValue)
                  : '-',
              subText: '-',
              link: null
            },
            {
              id: 22,
              name: 'Profit Recovery',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? roundValue(projectDashboard.value.ProjectDashboardCalculations[0].performanceProfitRecovery)
                  : '-',
              subText: '-',
              link: `/projects/${projectId}/timesheets`
            },
            {
              id: 23,
              name: 'Daily Profit',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? intlFormat(
                      roundAmount(projectDashboard.value.ProjectDashboardCalculations[0].performanceDailyProfit)
                    )
                  : '-',
              subText: '-',
              link: null
            }
          ]
        },
        {
          id: 3,
          blockHeading: 'Growth',
          score:
            projectDashboard.value.ProjectDashboardCalculations.length > 0
              ? intlFormat(roundAmount(projectDashboard.value.ProjectDashboardCalculations[0].growthFutureValue))
              : '-',
          tooltipText:
            'How confident you are about your future? Can you confidently see future sales with this project / client and is your capacity matched accordingly?',
          fields: [
            {
              id: 31,
              name: 'Future Value',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? intlFormat(roundAmount(projectDashboard.value.ProjectDashboardCalculations[0].growthFutureValue))
                  : '-',
              subText: '-',
              link: null
            },
            {
              id: 32,
              name: 'Cash Forecast',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? intlFormat(roundAmount(projectDashboard.value.ProjectDashboardCalculations[0].growthCashForecast))
                  : '-',
              subText: '-',
              link: null
            },
            {
              id: 33,
              name: 'Team Size',
              percentage:
                projectDashboard.value.ProjectDashboardCalculations.length > 0
                  ? projectDashboard.value.ProjectDashboardCalculations[0].growthTeam
                  : '-',
              subText: '-',
              link: null
            }
          ]
        }
      ]
    })
    const wideBlockData = computed(() => {
      return [
        {
          id: 4,
          blockHeading: 'Your Tasks',
          content: taskContent
        },
        {
          id: 5,
          blockHeading: 'Insights',
          content: [
            {
              id: 51,
              taskName: 'Invoice cleared',
              projectName: 'PJ Sullivan',
              link: null
            },
            {
              id: 52,
              taskName: 'SOW created',
              projectName: 'PJ Sullivan',
              link: null
            },
            {
              id: 53,
              taskName: 'Invoice Terms signed',
              projectName: 'PJ Sullivan',
              link: null
            }
          ]
        }
      ]
    })
    return { overViewBlockData, wideBlockData, projectId }
  }
})
</script>

<style lang="sass" scoped>
.block-wrapper
  width: 100%
  display: grid
  grid-template-columns: repeat(6, 1fr)
  justify-content: space-between
  grid-gap: 1rem
  @media screen and (max-width: $ms-breakpoint)
    grid-template-columns: 1fr
  .subtext
    color: $text-grey
    margin: 0.5rem
  a
    text-decoration: none
</style>
