<template>
  <div class="wideBlock">
    <slot name="wideBlockContent" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="sass">
.wideBlock
  width: 100%
  grid-column: span 3 / auto
  background: $white
  padding-top: 1rem
  &__row
    display: flex
    padding: 1rem 2rem
    justify-content: space-between
    align-items: center
    border-bottom: solid 1px map-get($greyShades, 'greyShade10')
  &__heading
    font-weight: normal
    margin: 0
  &__status
    background: $green
    width: 16px
    height: 16px
    border-radius: 50%
    margin-left: 0.7rem
    display: inline-block
  &__result
    font-size: 2.5rem
    margin: 0
  &__performance
    .avg
      background: $primary
      color: $white
      width: 50px
      padding: 0.4rem
      text-align: center
      margin: 10px 0 10px auto
      span
        color: map-get($greyShades, 'greyShade60')
        font-size: 0.75rem
  .green-dot
    background: $green
  .mustard-dot
    background: $mustard
  .red-dot
    background: $red
  .purple-dot
    background: $purple
  .dot
    width: 10px
    height: 10px
    border-radius: 50%
    margin-right: 0.7rem
    display: inline-block
  .block_datetime
    color: $text-grey
</style>
